<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPersonalizacao.banner_meus_cursos + ')', backgroundColor: '#fff' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Meus cursos</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Meus cursos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row pt-2 pb-2 mb-4 d-none fast-iuea-only-mobile fast-iuea-menu-mobile">
            <ul class="fd-side-menu-nav-list">
              <li
                data-toggle="collapse"
                href="#collapseUsuarios"
                role="button"
                aria-expanded="false"
                aria-controls="collapseUsuarios"
                class="fd-side-menu-nav-list-item"
              >
                <a
                  v-if="!$store.state.fastPersonalizacao.meus_cursos_nome"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                >
                  <h4 class="text-white">Meus Cursos</h4>
                </a>
                <a
                  v-else
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                >
                  <h4 class="text-white">{{ $store.state.fastPersonalizacao.meus_cursos_nome }}</h4>
                </a>
              </li>
              <li class="fd-side-menu-nav-list-item fast-menu-lives-aluno-iuea">
                <a
                  v-if="!$store.state.fastPersonalizacao.minhas_lives_nome"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
                >
                  <h4 class="text-white">Ao vivo</h4>
                </a>
                <a
                  v-else
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
                >
                  <h4 class="text-white">{{ $store.state.fastPersonalizacao.minhas_lives_nome }}</h4>
                </a>
              </li>
              <li class="fd-side-menu-nav-list-item fast-menu-lives-aluno-fast">
                <a
                  v-if="!$store.state.fastPersonalizacao.minhas_lives_nome"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
                >
                  <h4 class="text-white">Minhas lives</h4>
                </a>
                <a
                  v-else
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
                >
                  <h4 class="text-white">{{ $store.state.fastPersonalizacao.minhas_lives_nome }}</h4>
                </a>
              </li>
              <li class="fd-side-menu-nav-list-item">
                <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/atendimento'">
                  <h4 class="text-white">Atendimento</h4>
                </a>
              </li>

              <li class="fd-side-menu-nav-list-item">
                <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/mural'">
                  <h4 class="text-white">Mural</h4>
                </a>
              </li>
              <li class="fd-side-menu-nav-list-item">
                <a
                  v-if="!$store.state.fastPersonalizacao.meus_cadernos_nome"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos'"
                >
                  <h4 class="text-white">Meus cadernos</h4>
                </a>
                <a
                  v-else
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos'"
                >
                  <h4 class="text-white">{{ $store.state.fastPersonalizacao.meus_cadernos_nome }}</h4>
                </a>
              </li>
              <li class="fd-side-menu-nav-list-item fast-plataforma-iuea-hide">
                <a
                  v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-capacitacoes'"
                >
                  <h4 class="text-white">Minhas capacitações</h4>
                </a>
                <a
                  v-else
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-capacitacoes'"
                >
                  <h4
                    class="text-white"
                  >{{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}</h4>
                </a>
              </li>
              <li class="fd-side-menu-nav-list-item fast-plataforma-iuea-hide">
                <a
                  v-if="!$store.state.fastPersonalizacao.loja_plataforma_nome"
                  :href="'/loja-virtual/' + $route.params.id_plataforma"
                >
                  <h4 class="text-white">Loja da plataforma</h4>
                </a>
                <a
                  v-else
                  :href="'/loja-virtual/' + $route.params.id_plataforma"
                >
                  <h4 class="text-white">{{ $store.state.fastPersonalizacao.loja_plataforma_nome }}</h4>
                </a>
              </li>
            </ul>
          </div>
          <div class="row">
            <transition name="slide-fade">
              <transition name="slide-fade">
                <component
                  :is="HomeInternoAlunoMenuLateral"
                  v-if="!isMobile()"
                />
              </transition>
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                            <h1
                              v-if="!$store.state.fastPersonalizacao.meus_cursos_nome"
                              class="aluno_font_color"
                            >
                              Meus
                              <span>cursos</span>
                            </h1>
                            <h1
                              v-else
                              class="aluno_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.meus_cursos_nome }}
                            </h1>
                            <p class="aluno_font_color">
                              Veja os cursos disponíveis para você
                            </p>
                            <div class="fd-welcome-form-effect">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <section
                            class="fd-app-welcome text-left d-none fast-menu-lives-aluno-iuea mt-4 mb-4 ml-4"
                          >
                            <h1 v-if="!$store.state.fastPersonalizacao.meus_cursos_nome">
                              Meus cursos
                            </h1>
                            <h1 v-else>
                              {{ $store.state.fastPersonalizacao.meus_cursos_nome }}
                            </h1>
                          </section>
                          <!-- /fd-app-welcome -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <section class="fd-app-meus-cursos mt-0">
                            <div class="fd-app-meus-cursos-list">
                              <div
                                v-if="fastCursosLoading"
                                class="row mt-4"
                              >
                                <div class="col-12 text-center text-secondary">
                                  <h4>
                                    <b-icon
                                      icon="gear-fill"
                                      animation="spin"
                                    /> Carregando
                                  </h4>
                                </div>
                              </div>
                              <div
                                v-else
                                class="row"
                              >
                                <div
                                  v-for="curso in fastCursos"
                                  :key="curso.id_curso"
                                  class="col-sm-12 col-md-12 col-lg-5 mb-4"
                                >
                                  <div class="fd-app-meus-cursos-list-item">
                                    <a
                                      href="#"
                                      @click.prevent="acessaCurso(curso)"
                                    >
                                      <div class="fd-app-meus-cursos-list-item-header">
                                        <h3 v-if="curso.nome_curso" class="aluno_font_color">{{ curso.nome_curso }}</h3>
                                        <h3 v-else class="aluno_font_color">{{ curso.nome_curso_mae }}</h3>

                                        <p v-if="curso.detalhe_curso">{{ curso.detalhe_curso }}</p>
                                        <p v-else>{{ curso.detalhe_curso_mae }}</p>
                                      </div>
                                      <div class="fd-app-meus-cursos-list-item-body">

                                        <img
                                          v-if="curso.logo_curso"
                                          :src="
                                            curso.logo_curso
                                              ? ajustaLinkApiAntiga(curso.logo_curso)
                                              : imageSitePlataformaImg
                                          "
                                          style="max-height: 170px"
                                          class="img-fluid"
                                        >
                                        <img
                                          v-else
                                          :src="
                                            curso.logo_curso_mae
                                              ? ajustaLinkApiAntiga(curso.logo_curso_mae)
                                              : imageSitePlataformaImg
                                          "
                                          style="max-height: 170px"
                                          class="img-fluid"
                                        >


                                        <div v-if="curso.iniciado">
                                          <span
                                            v-if="curso.encerrado"
                                            class="fd-app-meus-cursos-list-item-label fd-app-meus-cursos-list-item-label-success"
                                          >Realizado</span>
                                          <span
                                            v-else
                                            class="fd-app-meus-cursos-list-item-label fd-app-meus-cursos-list-item-label-warning"
                                          >Em andamento</span>
                                        </div>
                                        <span
                                          v-else
                                          class="fd-app-meus-cursos-list-item-label fd-app-meus-cursos-list-item-label-danger"
                                        >Não Realizado</span>
                                        <div class="d-flex mt-4">
                                          <ul class="fd-app-meus-cursos-list-item-infolist">
                                            <li>
                                              <strong>Início:</strong>
                                              {{ new Date(curso.data_disponivel).toLocaleDateString() }}
                                            </li>
                                          </ul>
                                          <div class="fd-app-meus-cursos-list-item-action ml-auto">
                                            <div
                                              class="col-sm-12 col-md-12 col-lg-12 text-center mb-2"
                                            >
                                              <a
                                                v-if="verificaDataCurso(curso)"
                                                href="#"
                                                class="btn btn-primary"
                                              >Entrar</a>
                                              <h6
                                                v-else
                                                class="text-danger"
                                              >Aguarde a liberação</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div class="col-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";
import imageSiteMatrizIcon from "@/assets/images/app/matriz-icon.png";
import imageSiteCursosIcon from "@/assets/images/app/cursos-icon.png";
import imageSiteUsuariosIcon from "@/assets/images/app/usuarios-icon.png";
import imageSitePersonalizarIcon from "@/assets/images/app/personalizar-icon.png";
import imageSiteOndemandIcon from "@/assets/images/app/ondemand-icon.png";
import imageSiteMuralIcon from "@/assets/images/app/mural-icon.png";
import imageSiteFinanceiro from "@/assets/images/app/financeiro-icon.png";
import imageSiteCapacitacoesIcon from "@/assets/images/app/capacitacoes-icon.png";
import imageSiteCertificadosIcon from "@/assets/images/app/certificados-icon.png";
import imageSiteAtendimentoIcon from "@/assets/images/app/atendimento-icon.png";
import imageSiteAlarmIcon from "@/assets/images/app/alarm-icon.png";
import muralAvisos from "../components/MuralAvisos";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";

export default {
  name: "HomeInternoAlunoMeusCursos",
  components: {
    muralAvisos
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      //ImageSiteFormEffect01,
      imageSiteMatrizIcon,
      imageSiteCursosIcon,
      imageSiteUsuariosIcon,
      imageSitePersonalizarIcon,
      imageSiteOndemandIcon,
      imageSiteMuralIcon,
      imageSiteFinanceiro,
      imageSiteAlarmIcon,
      imageSiteAtendimentoIcon,
      imageSiteCertificadosIcon,
      imageSiteCapacitacoesIcon,
      imageSitePlataformaImg,
      // Cursos
      fastCursos: [],
      fastCursosLoading: true
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.exibeCursos();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async exibeCursos() {
      this.fastCursosLoading = true
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          let cursosPublicados = obj.filter((e) => e.publicado);
          cursosPublicados.forEach((c) => {
            c.iniciado = false;
            c.encerrado = false;
          });
          this.fastCursos = cursosPublicados;
          this.fastCursos.forEach((e) => this.exibeCursoAluno(e.id_curso));
        }
        this.$store.state.fastCarregando = false;
        this.fastCursosLoading = false
      } catch (e) {
        console.log("Erro", e);
        this.fastCursosLoading = false
      }
    },
    async exibeCursoAluno(id_curso) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/lista?id_curso=" +
            id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();

        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fastCursos.forEach((e, index) => {
            if (id_curso == e.id_curso) {
              this.fastCursos[index].iniciado = true;
              this.fastCursos[index].encerrado = obj[0].encerrado;
            }
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    verificaDataCurso(curso) {
      let data_atual = new Date();
      let data_disponivel = new Date(curso.data_disponivel);
      let data_expiracao = new Date(curso.data_expiracao);
      if (data_atual >= data_disponivel && data_atual <= data_expiracao)
        return true;
      else return false;
    },
    acessaCurso(curso) {
      if (this.verificaDataCurso(curso)) {
         this.$router.push('/plataforma/' + this.$route.params.id_plataforma + '/aluno/meus-cursos/curso/' + curso.id_curso)
      }
    },
  },
};
</script>

<style></style>
